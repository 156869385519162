.controls{
    display: flex;   
    align-items: center;
    justify-content: center;
}
.group_controls{
    border-width: 1px;
    border-style: solid;
    margin-left: 5px;
    
}
