.unique_post_exemple{
    width:500px;
    margin:auto;
    margin-top:30px;
}
.check_list_form_div{
   display: flex;
   flex-direction: column;
   width: 450px;
   margin: auto;
   margin-top: 15px;
};



@media (max-width: 500px)
{
  .unique_post_exemple{
      width: 300px;
  };
  .check_list_form_div{
    width: 300px;
  };

}