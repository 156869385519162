.terms{
    width:60%;
    margin: auto;
    margin-top: 50px;
}

.term_block{
    margin-top: 25px;
    margin-bottom: 15px;
    text-align: left;
}
.subtitle{
    font-size: 18px;
    font-weight: 700;
}
.content{
    font-size: 13px;
    color:#000000bd;
    text-align: left;
    margin-top: 8px;
}
.no_margin_bottom{
    margin-bottom: 0px;
}
.no_margin_top{
    margin-top: 0px;
}
.content a{
    text-decoration: underline;
    color:#000000bd;
}
.zero_margin{
    margin-top: 0px;
    margin-bottom: 0px;
}
.list_item{
    margin-left: 40px;
    display: list-item;
    list-style-position: outside;
    list-style-type: disc;
}
.strong{
    font-weight: 700;
}


@media (max-width: 700px)
{
  .terms{
      width: 90%;
  }
}