.models_list{
    display: block;
    width:600px;
    margin: auto;
    margin-top: 25px;
    max-width: 100%;
  };
  @media (max-width: 500px)
  {
    .models_list{
        width: 300px;
     };
  }