.react-calendar__tile--active{
    background-color: inherit;
    color:initial;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #e6e6e6;
}
.has_schedule{
    background-color: #17732369;
}

.insta_select{
   width:500px;
   max-width: 100%;
   margin:auto;
}

@media (max-width: 300px)
{
   .insta_select{
      width: 300px;
   }
}