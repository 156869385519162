.image_thumb{
    border-style: solid;
    border-width: 1px;
    border-color:rgb(84, 85, 85,0.1);
    
}
.image_thumb:hover{
    border-color:rgb(84, 85, 85,0.6);
}

.galery{
    border-style: solid;
    border-width: 1px;
    border-color:rgb(84, 85, 85,0.1);
}