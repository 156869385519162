@keyframes mymove {
    50% {border-color: lightblue;}
}

.coord{
    border-style: dotted;
    border-width: 2px;
    border-color:coral;
    cursor: move;
    position: absolute;
    animation: mymove 5s infinite;
}

.coord_pointer{
    border-style: solid ;
    border-width: 1px;
    border-color: coral;
    border-radius: 100%;
    background-color: #fff;

    width: 10px;
    height: 10px;
    position: absolute;
    left: 0px;
    top:0px;
    transform: translate(-50%, -50%);
}
.nwse{
    cursor:nwse-resize;
}
.nesw{
    cursor: nesw-resize;
}